import Cookie from 'js-cookie';
let favoritos;

try {
    favoritos = JSON.parse(Cookie.get('favoritos') || '[]');
} catch (error) {
    favoritos = []
}

$(document).on('click', '.btn-add-favorito', function () {
    let codigo = $(this).data('codigo');
    if (favoritos.includes(codigo)) {
        favoritos = favoritos.filter(c => c != codigo);
        $(this).removeClass('active')
    } else {
        favoritos.push(codigo);
        $(this).addClass('active')
    }
    Cookie.set('favoritos', JSON.stringify(favoritos), { path: '/', expires: 365000 });
})

$('.btn-add-favorito').each(function () {
    let codigo = $(this).data('codigo');
    if (favoritos.includes(codigo)) {
        $(this).addClass('active')
    }
})