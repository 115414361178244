import Swal from "sweetalert2";

$(document).on('input', '[alt=phone]', function () {
    let telefone = $(this).val().replace(/\D/igm, '');
    $(this).unsetMask();
    if (10 >= telefone.length) {
        $(this).setMask('(99) 9999-99999');
    } else {
        $(this).setMask('(99) 99999-9999');
    }
});

$(document).on('submit', '[name=formNewsletter]', async function (evt) {
    evt.preventDefault();
    evt.stopPropagation();

    let $form = $(this);
    let $btn = $form.find('input[type=submit]');
    if (!$btn.data('default-text')) {
        $btn.data('default-text', $btn.val());
    }

    try {
        if ($form.data('enviando')) {
            return false;
        }
        let nome = $form.find('[name=nome]').val();
        let email = $form.find('[name=email]').val();

        await new Promise((resolve, reject) => grecaptcha.ready(resolve))
        let token = await grecaptcha.execute(window.recaptcha_site_key, { action: 'submit' })
        $btn.val("Enviando...");

        await new Promise((resolve, reject) => {
            $.ajax({
                url: 'contato/send-newsletter',
                type: 'post',
                dataType: 'json',
                data: {
                    nome,
                    email,
                    token
                }
            })
                .then(resolve)
                .fail((xhr, textStatus, err) => {
                    console.error(err);
                    let message = xhr.responseJSON?.error || "Não foi possível enviar o formulário"

                    reject(new Error(message));
                })
        });

        Swal.fire({
            icon: 'success',
            title: "Cadastro realizado com sucesso"
        });

        $form[0].reset();

        window.dataLayer.push({
            event: "formSubmit",
            formId: $form.attr('name'),
            formTitle: "Newsletter",
            fields: [
                {
                    name: "nome",
                    value: nome
                },
                {
                    name: "email",
                    value: email
                }
            ]
        })
    } catch (error) {
        console.error(error);
        let text = error.resonse?.data?.error || error.message || "Não foi possível enviar o formulário";

        Swal.fire({
            icon: 'error',
            title: 'Oops',
            text
        });
    }

    $form.data('enviando', false);
    $btn.val($btn.data('default-text'));
});

$(document).on('submit', '[name=formTenhoInteresse]', async function (evt) {
    evt.preventDefault();
    evt.stopPropagation();

    let $form = $(this);
    let $btn = $form.find('input[type=submit]');
    if (!$btn.data('default-text')) {
        $btn.data('default-text', $btn.val());
    }

    try {
        if ($form.data('enviando')) {
            return false;
        }
        let nome = $form.find('[name=nome]').val();
        let email = $form.find('[name=email]').val();
        let telefone = $form.find('[name=telefone]').val();
        let mensagem = $form.find('[name=mensagem]').val();
        let imovel = $form.find('[name=imovel]').val();
        let corretor = $form.find('[name=corretor]').val();

        $btn.val("Enviando...");
        await new Promise((resolve, reject) => grecaptcha.ready(resolve))
        let token = await grecaptcha.execute(window.recaptcha_site_key, { action: 'submit' })

        await new Promise((resolve, reject) => {
            $.ajax({
                url: 'imovel/send-interesse',
                type: 'post',
                dataType: 'json',
                data: {
                    nome,
                    email,
                    telefone,
                    mensagem,
                    imovel,
                    corretor,
                    token
                }
            })
                .then(resolve)
                .fail((xhr, textStatus, err) => {
                    console.error(err);
                    let message = xhr.responseJSON?.error || "Não foi possível enviar o formulário"

                    reject(new Error(message));
                })
        });

        Swal.fire({
            icon: 'success',
            title: "Formulário enviado com sucesso"
        });

        window.dataLayer.push({
            event: "formSubmit",
            formId: $form.attr('name'),
            formTitle: "Tenho Interesse",
            fields: [
                {
                    name: "nome",
                    value: nome
                },
                {
                    name: "email",
                    value: email
                },
                {
                    name: "telefone",
                    value: telefone
                },
                {
                    name: "mensagem",
                    value: mensagem
                },
                {
                    name: "imovel",
                    value: imovel
                },
                {
                    name: "corretor",
                    value: corretor
                },
            ]
        })

        $form[0].reset();
    } catch (error) {
        console.error(error);
        let text = error.resonse?.data?.error || error.message || "Não foi possível enviar o formulário";

        Swal.fire({
            icon: 'error',
            title: 'Oops',
            text
        });
    }

    $form.data('enviando', false);
    $btn.val($btn.data('default-text'));
});

$(document).on('submit', '[name=formContato]', async function (evt) {
    evt.preventDefault();
    evt.stopPropagation();

    let $form = $(this);
    let $btn = $form.find('input[type=submit]');
    if (!$btn.data('default-text')) {
        $btn.data('default-text', $btn.val());
    }

    try {
        if ($form.data('enviando')) {
            return false;
        }
        let nome = $form.find('[name=nome]').val();
        let email = $form.find('[name=email]').val();
        let telefone = $form.find('[name=telefone]').val();
        let assunto_id = $form.find('[name=assunto_id]').val();
        let mensagem = $form.find('[name=mensagem]').val();
        let assunto = $form.find(`[name=assunto] > option[value=${assunto_id}]`).text()

        $btn.val("Enviando...");
        await new Promise((resolve, reject) => grecaptcha.ready(resolve))
        let token = await grecaptcha.execute(window.recaptcha_site_key, { action: 'submit' })

        await new Promise((resolve, reject) => {
            $.ajax({
                url: 'contato/send',
                type: 'post',
                dataType: 'json',
                data: {
                    nome,
                    email,
                    telefone,
                    assunto_id,
                    mensagem,
                    token
                }
            })
                .then(resolve)
                .fail((xhr, textStatus, err) => {
                    console.error(err);
                    let message = xhr.responseJSON?.error || "Não foi possível enviar o formulário"

                    reject(new Error(message));
                })
        });

        Swal.fire({
            icon: 'success',
            title: "Formulário enviado com sucesso"
        });

        window.dataLayer.push({
            event: "formSubmit",
            formId: $form.attr('name'),
            formTitle: "Contato",
            fields: [
                {
                    name: "nome",
                    value: nome
                },
                {
                    name: "email",
                    value: email
                },
                {
                    name: "telefone",
                    value: telefone
                },
                {
                    name: "assunto",
                    value: assunto
                },
                {
                    name: "mensagem",
                    value: mensagem
                },
            ]
        })

        $form[0].reset();
    } catch (error) {
        console.error(error);
        let text = error.resonse?.data?.error || error.message || "Não foi possível enviar o formulário";

        Swal.fire({
            icon: 'error',
            title: 'Oops',
            text
        });
    }

    $form.data('enviando', false);
    $btn.val($btn.data('default-text'));
});

$(document).on('submit', '[name=formAnunciar]', async function (evt) {
    evt.preventDefault();
    evt.stopPropagation();

    let $form = $(this);
    let $btn = $form.find('input[type=submit]');
    if (!$btn.data('default-text')) {
        $btn.data('default-text', $btn.val());
    }

    try {
        if ($form.data('enviando')) {
            return false;
        }
        let nome = $form.find('[name=nome]').val();
        let email = $form.find('[name=email]').val();
        let telefone = $form.find('[name=telefone]').val();

        $btn.val("Enviando...");
        await new Promise((resolve, reject) => grecaptcha.ready(resolve))
        let token = await grecaptcha.execute(window.recaptcha_site_key, { action: 'submit' })

        await new Promise((resolve, reject) => {
            $.ajax({
                url: 'anunciar/send',
                type: 'post',
                dataType: 'json',
                data: {
                    nome,
                    email,
                    telefone,
                    token
                }
            })
                .then(resolve)
                .fail((xhr, textStatus, err) => {
                    console.error(err);
                    let message = xhr.responseJSON?.error || "Não foi possível enviar o formulário"

                    reject(new Error(message));
                })
        });

        Swal.fire({
            icon: 'success',
            title: "Formulário enviado com sucesso"
        });

        window.dataLayer.push({
            event: "formSubmit",
            formId: $form.attr('name'),
            formTitle: "Anunciar",
            fields: [
                {
                    name: "nome",
                    value: nome
                },
                {
                    name: "email",
                    value: email
                },
                {
                    name: "telefone",
                    value: telefone
                },
            ]
        })

        $form[0].reset();
    } catch (error) {
        console.error(error);
        let text = error.resonse?.data?.error || error.message || "Não foi possível enviar o formulário";

        Swal.fire({
            icon: 'error',
            title: 'Oops',
            text
        });
    }

    $form.data('enviando', false);
    $btn.val($btn.data('default-text'));
});

$(document).on('submit', '[name=formLigacao]', async function (evt) {
    evt.preventDefault();
    evt.stopPropagation();

    let $form = $(this);
    let $btn = $form.find('input[type=submit]');
    if (!$btn.data('default-text')) {
        $btn.data('default-text', $btn.val());
    }

    try {
        if ($form.data('enviando')) {
            return false;
        }
        let nome = $form.find('[name=nome]').val();
        let telefone = $form.find('[name=telefone]').val();

        await new Promise((resolve, reject) => grecaptcha.ready(resolve))
        let token = await grecaptcha.execute(window.recaptcha_site_key, { action: 'submit' })
        $btn.val("Enviando...");

        await new Promise((resolve, reject) => {
            $.ajax({
                url: 'contato/send-ligacao',
                type: 'post',
                dataType: 'json',
                data: {
                    nome,
                    telefone,
                    token
                }
            })
                .then(resolve)
                .fail((xhr, textStatus, err) => {
                    console.error(err);
                    let message = xhr.responseJSON?.error || "Não foi possível enviar o formulário"

                    reject(new Error(message));
                })
        });

        Swal.fire({
            icon: 'success',
            title: "Solicitação enviada com sucesso"
        });

        window.dataLayer.push({
            event: "formSubmit",
            formId: $form.attr('name'),
            formTitle: "Quero receber uma ligação",
            fields: [
                {
                    name: "nome",
                    value: nome
                },
                {
                    name: "telefone",
                    value: telefone
                }
            ]
        })

        $form[0].reset();
    } catch (error) {
        console.error(error);
        let text = error.resonse?.data?.error || error.message || "Não foi possível enviar o formulário";

        Swal.fire({
            icon: 'error',
            title: 'Oops',
            text
        });
    }

    $form.data('enviando', false);
    $btn.val($btn.data('default-text'));
});

$(document).on('submit', '[name=formMaisInformacoes]', async function (evt) {
    evt.preventDefault();
    evt.stopPropagation();

    let $form = $(this);
    let $btn = $form.find('input[type=submit]');
    if (!$btn.data('default-text')) {
        $btn.data('default-text', $btn.val());
    }

    try {
        if ($form.data('enviando')) {
            return false;
        }
        let nome = $form.find('[name=nome]').val();
        let email = $form.find('[name=email]').val();
        let telefone = $form.find('[name=telefone]').val();
        let imovel = $form.find('[name=imovel]').val();
        let corretor = $form.find('[name=corretor]').val();

        $btn.val("Enviando...");
        await new Promise((resolve, reject) => grecaptcha.ready(resolve))
        let token = await grecaptcha.execute(window.recaptcha_site_key, { action: 'submit' })

        await new Promise((resolve, reject) => {
            $.ajax({
                url: 'imovel/mais-informacoes',
                type: 'post',
                dataType: 'json',
                data: {
                    nome,
                    email,
                    telefone,
                    imovel,
                    corretor,
                    token
                }
            })
                .then(resolve)
                .fail((xhr, textStatus, err) => {
                    console.error(err);
                    let message = xhr.responseJSON?.error || "Não foi possível enviar o formulário"

                    reject(new Error(message));
                })
        });

        Swal.fire({
            icon: 'success',
            title: "Formulário enviado com sucesso"
        });

        window.dataLayer.push({
            event: "formSubmit",
            formId: $form.attr('name'),
            formTitle: "Mais Informações",
            fields: [
                {
                    name: "nome",
                    value: nome
                },
                {
                    name: "email",
                    value: email
                },
                {
                    name: "telefone",
                    value: telefone
                },
                {
                    name: "imovel",
                    value: imovel
                },
                {
                    name: "corretor",
                    value: corretor
                },
            ]
        })

        $form[0].reset();
    } catch (error) {
        console.error(error);
        let text = error.resonse?.data?.error || error.message || "Não foi possível enviar o formulário";

        Swal.fire({
            icon: 'error',
            title: 'Oops',
            text
        });
    }

    $form.data('enviando', false);
    $btn.val($btn.data('default-text'));
});


$(document).on('submit', '[name=formAgendarVisita]', async function (evt) {
    evt.preventDefault();
    evt.stopPropagation();

    let $form = $(this);
    let $btn = $form.find('input[type=submit]');
    if (!$btn.data('default-text')) {
        $btn.data('default-text', $btn.val());
    }

    try {
        if ($form.data('enviando')) {
            return false;
        }
        let nome = $form.find('[name=nome]').val();
        let email = $form.find('[name=email]').val();
        let telefone = $form.find('[name=telefone]').val();
        let imovel = $form.find('[name=imovel]').val();
        let corretor = $form.find('[name=corretor]').val();
        let data = $form.find('[name=data]').val();
        let horario = $form.find('[name=horario]').val();

        $btn.val("Enviando...");
        await new Promise((resolve, reject) => grecaptcha.ready(resolve))
        let token = await grecaptcha.execute(window.recaptcha_site_key, { action: 'submit' })

        await new Promise((resolve, reject) => {
            $.ajax({
                url: 'imovel/agendar-visita',
                type: 'post',
                dataType: 'json',
                data: {
                    nome,
                    email,
                    telefone,
                    imovel,
                    corretor,
                    data,
                    horario,
                    token
                }
            })
                .then(resolve)
                .fail((xhr, textStatus, err) => {
                    console.error(err);
                    let message = xhr.responseJSON?.error || "Não foi possível enviar o formulário"

                    reject(new Error(message));
                })
        });

        Swal.fire({
            icon: 'success',
            title: "Formulário enviado com sucesso"
        });

        window.dataLayer.push({
            event: "formSubmit",
            formId: $form.attr('name'),
            formTitle: "Mais Informações",
            fields: [
                {
                    name: "nome",
                    value: nome
                },
                {
                    name: "email",
                    value: email
                },
                {
                    name: "telefone",
                    value: telefone
                },
                {
                    name: "imovel",
                    value: imovel
                },
                {
                    name: "corretor",
                    value: corretor
                },
                {
                    name: "data",
                    value: data
                },
                {
                    name: "horario",
                    value: horario
                },
            ]
        })

        $form[0].reset();
    } catch (error) {
        console.error(error);
        let text = error.resonse?.data?.error || error.message || "Não foi possível enviar o formulário";

        Swal.fire({
            icon: 'error',
            title: 'Oops',
            text
        });
    }

    $form.data('enviando', false);
    $btn.val($btn.data('default-text'));
});




$(document).on('submit', '[name=formSelect]', async function (evt) {
    evt.preventDefault();
    evt.stopPropagation();

    let $form = $(this);
    let $btn = $form.find('[type=submit]');
    if (!$btn.data('default-text')) {
        $btn.data('default-text', $btn.html());
    }

    try {
        if ($form.data('enviando')) {
            return false;
        }
        let nome = $form.find('[name=nome]').val();
        let email = $form.find('[name=email]').val();
        let telefone = $form.find('[name=telefone]').val();        


        if ($btn.data('whatsapp')) {
            window.open($btn.data('whatsapp'), '_blank')
        }

        $btn.html("Enviando...");
        await new Promise((resolve, reject) => grecaptcha.ready(resolve))
        let token = await grecaptcha.execute(window.recaptcha_site_key, { action: 'submit' })

        await new Promise((resolve, reject) => {
            $.ajax({
                url: 'select/send',
                type: 'post',
                dataType: 'json',
                data: {
                    nome,
                    email,
                    telefone,
                    token
                }
            })
                .then(resolve)
                .fail((xhr, textStatus, err) => {
                    console.error(err);
                    let message = xhr.responseJSON?.error || "Não foi possível enviar o formulário"

                    reject(new Error(message));
                })
        });

        Swal.fire({
            icon: 'success',
            title: "Formulário enviado com sucesso"
        });

        window.dataLayer.push({
            event: "formSubmit",
            formId: $form.attr('name'),
            formTitle: "Quero ser Select",
            fields: [
                {
                    name: "nome",
                    value: nome
                },
                {
                    name: "email",
                    value: email
                },
                {
                    name: "telefone",
                    value: telefone
                }
            ]
        })

        $form[0].reset();
        $form.find('[name=telefone]').val('')
    } catch (error) {
        console.error(error);
        let text = error.resonse?.data?.error || error.message || "Não foi possível enviar o formulário";

        Swal.fire({
            icon: 'error',
            title: 'Oops',
            text
        });
    }

    $form.data('enviando', false);
    $btn.html($btn.data('default-text'));
});

const enviarWhatsappModal = new bootstrap.Modal(document.querySelector('#enviar-whatsapp'), {})
let whatsapp_url = ''

$(document).on('click', '.btn-enviar-whatsapp', function () {
    const href = $(this).prop('href');
    whatsapp_url = href
    enviarWhatsappModal.show()
    return false
})

$(document).on('submit', '[name=formEnviarWhatsapp]', async function (evt) {
    evt.preventDefault();
    evt.stopPropagation();

    let $form = $(this);
    let $btn = $form.find('input[type=submit]');
    if (!$btn.data('default-text')) {
        $btn.data('default-text', $btn.val());
    }

    try {
        if ($form.data('enviando')) {
            return false;
        }
        let nome = $form.find('[name=nome]').val();
        let email = $form.find('[name=email]').val();
        let telefone = $form.find('[name=telefone]').val();                


        const a = document.createElement('a')
        a.href = whatsapp_url
        a.target = '_blank'
        a.click()

        $btn.val("Enviando...");
        await new Promise((resolve, reject) => grecaptcha.ready(resolve))
        let token = await grecaptcha.execute(window.recaptcha_site_key, { action: 'submit' })

        await new Promise((resolve, reject) => {
            $.ajax({
                url: 'enviar-whatsapp/enviar',
                type: 'post',
                dataType: 'json',
                data: {
                    nome,
                    email,
                    telefone,                                        
                    token
                }
            })
                .then(resolve)
                .fail((xhr, textStatus, err) => {
                    console.error(err);
                    let message = xhr.responseJSON?.error || "Não foi possível enviar o formulário"

                    reject(new Error(message));
                })
        });

        

        Swal.fire({
            icon: 'success',
            title: "Formulário enviado com sucesso"
        });

        window.dataLayer.push({
            event: "formSubmit",
            formId: $form.attr('name'),
            formTitle: "Enviar Whatsapp",
            fields: [
                {
                    name: "nome",
                    value: nome
                },
                {
                    name: "email",
                    value: email
                },
                {
                    name: "telefone",
                    value: telefone
                },
            ]
        })

        $form[0].reset();
    } catch (error) {
        console.error(error);
        let text = error.resonse?.data?.error || error.message || "Não foi possível enviar o formulário";

        Swal.fire({
            icon: 'error',
            title: 'Oops',
            text
        });
    }

    $form.data('enviando', false);
    $btn.val($btn.data('default-text'));
});