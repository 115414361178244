
$(document).on('change', '.modal [name=cidade]', function () {
    let cidade_id = $(this).val();
    $('.modal [data-cidade]').hide();
    $(`.modal [data-cidade="${cidade_id}"]`).show();
});

$('.modal [name=cidade]').trigger('change');

$(document).on('click', '.btn-set-bairros', function () {
    let $form = $('[name=formBusca]');
    $form.find('[name^=bairros]').remove();
    let bairros = $('.modal [name^=bairros]:checked').map(function () {
        return $(this).val()
    }).toArray();
    for (let bairro_id of bairros) {
        $form.append(`<input type="hidden" name="bairros[]" value="${bairro_id}" />`);
    }

    $('#count-bairros').text(`(${bairros.length})`);

});

$(document).on('click', '.btn-set-tipos', function () {
    let $form = $('[name=formBusca]');
    $form.find('[name^=tipos]').remove();
    let tipos = $('.modal [name^=categoria]:checked').map(function () {
        return $(this).val()
    }).toArray();
    for (let tipo_id of tipos) {
        $form.append(`<input type="hidden" name="tipos[]" value="${tipo_id}" />`);
    }

    $('#count-tipos').text(`(${tipos.length})`);

});


$(document).on('change', '[name=order]', function () {
    let search = new URLSearchParams(location.search);
    search.set('order', $(this).val());

    location.search = '?' + search;
});