import("./index.less").then(e => { })

import './mapa.js';
import './busca.js';
import './favoritos.js';
import './forms.js';


if ($('.popup').length) {
    $('.popup').each(function () {
        let $el = $(this);
        let time = $el.data('time') * 1000;
        setTimeout(() => {
            Fancybox.show([
                {
                    src: $el.html(),
                    type: 'html'
                }
            ])
        }, time);

    });
}

$(document).on('click', 'a.ver_valor', function () {
    let valor = $(this).data('valor');
    $(this).html(valor);
});